import React, { Component } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

class RegisterPassword extends Component {
  state = {
    passOne: "",
    passTwo: "",
    password: "",
    message: "",
    message2: "",
    message3: "",
    message4: "",
    message5: "",
    message6: "",
    ScreenMode: "",
    passwordSubmit: false,
  };

  addUser = async (e) => {
    const result = await axios.get(
      // `https://homefinderappnow.co.uk/adduser/${this.props.newEmail}/${this.props.newForename}/${this.props.newSurname}/${this.state.passOne}/`
      `http://localhost:5000/adduser/${this.props.newEmail}/${this.props.newForename}/${this.props.newSurname}/${this.state.passOne}/`      
    );
    if (result.data === true) {
      this.props.setScreenMode(1);
    } else {
      this.setState({ data: result.data });
    }
  };

  testPass = (e) => {
    if (e.target.value.length < 8) {
      this.setState({
        message: "Error: password must be more than 8 characters!",
      });
      return false;
    } else {
      this.setState({ message: "" });
    }

    let up = /[A-Z]/;
    if (!up.test(e.target.value)) {
      this.setState({
        message2:
          "Error: password must contain at least one uppercase letter (A-Z)!",
      });
      return false;
    } else {
      this.setState({ message2: "" });
    }

    let lo = /[a-z]/;
    if (!lo.test(e.target.value)) {
      this.setState({
        message3:
          "Error: password must contain at least one lowercase letter (a-z)!",
      });
      return false;
    } else {
      this.setState({ message3: "" });
    }

    let nu = /[0-9]/;
    if (!nu.test(e.target.value)) {
      this.setState({
        message4: "Error: password must contain at least one number (0-9)!",
      });
      return false;
    } else {
      this.setState({ message4: "" });
    }

    this.setState({ message5: "password correct" });
    this.setState({ passOne: e.target.value });
    return true;
  };

  passMatch = (e) => {
    if (this.state.passOne === this.state.passTwo) {
      this.setState({ message6: "it matches" });
    }
  };

  passT = (e) => {
    if (
      this.state.passOne === e.target.value &&
      this.state.passOne.length > 0
    ) {
      this.setState({ passwordSubmit: true });
    }

    this.passMatch();
  };

  render() {
    return (
      <div className="register-password-page">
        <p>Please register your password : {this.props.newEmail}</p>

        <p>Please Enter the password twice</p>

        <label htmlFor=""> Password</label>
        <input
          type="password"
          name="pwd1"
          // onInput={(e) => this.setState({ passOne: e.target.value })}
          onInput={(e) => this.testPass(e)}
        ></input>
        <p>{this.state.message}</p>
        <p>{this.state.message2}</p>
        <p>{this.state.message3}</p>
        <p>{this.state.message4}</p>
        <p>{this.state.message5}</p>

        <label htmlFor=""> Password</label>
        <input
          type="password"
          name="pwd2"
          onInput={(e) => {
            this.passT(e);
          }}
        ></input>
        <p>{this.state.passTwo}</p>
        <p>{this.state.message6}</p>

        {this.state.passwordSubmit === true ? (
          <Button
            as="input"
            type="button"
            value="Submit"
            onClick={() => this.addUser()}
          />
        ) : (
          <p></p>
        )}
      </div>
    );
  }
}

export default RegisterPassword;
