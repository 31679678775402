import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class PrevPageButton extends Component {
    render() { 
        return <Button onClick={() => this.props.setPreviousPage()} > Prev</Button>

    };
}
 
export default PrevPageButton;