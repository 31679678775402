import React, { Component } from "react";
import axios from "axios";
import BlueStar from "../../icon/blue-star.svg";
import WhiteStar from "../../icon/white-star.svg";

class SaveStar extends Component {
  state = {
    fill: "rating-star empty-star star-size",
    propertySaved: "Click to Save Property",
    starType: WhiteStar,
  };

  setOppositeColor = () => {
    if (this.state.fill === "rating-star empty-star star-size") {
      this.setState({
        fill: "rating-star full-star star-size",
        propertySaved: "Property Saved!!",
        starType: BlueStar,
      });
    } else {
      this.setState({
        fill: "rating-star empty-star star-size",
        propertySaved: "Click to Save Property",
        starType: WhiteStar,
      });
    }
  };

  addState = () => {
    this.setState({
      showFlag: this.props.showFlag,
    });
  };

  setSavedOnListing = () => {
    this.addState();
    if (this.props.showFlag === 1) {
      this.setState({
        fill: "rating-star full-star star-size",
        propertySaved: "Property Saved!!",
        starType: BlueStar,
      });
    } else {
      this.setState({
        fill: "rating-star empty-star star-size",
      });
    }
  };

  saveListingToDb = async () => {
    const result = await axios.get(
      // `https://homefinderappnow.co.uk/addenquiry/${this.props.email}/${this.props.listing_id}`
      `http://localhost:5000/addenquiry/${this.props.email}/${this.props.listingId}`
    );
  };

  function = () => {
    this.setOppositeColor();
    this.saveListingToDb();
  };

  componentDidMount() {
    this.setSavedOnListing();
  }

  render() {
    return (
      <>
        <div className="flex-listing-item star">
          <img src={this.state.starType} alt="star"
            className={this.state.fill && "star-size"} 
            onClick={() => this.function()}
          ></img>

        </div>
        <p className="flex-listing-item star-text">{this.state.propertySaved}</p>
      </>
    );
  }
}

export default SaveStar;
