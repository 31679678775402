import React, { Component } from "react";
import axios from "axios";

// navigationBar
import PrevPageButton from "./components/navigationBar/previous-page-button";
import NextPageButton from "./components/navigationBar/next-page-button";
import GoListingPage from "./components/navigationBar/go-to-listings";
import GoUserInfo from "./components/navigationBar/go-to-user-info";
import NavBar from "./components/navigationBar/nav-bar";
import SortListing from "./components/navigationBar/sort-listing";

// logInScreen
import LogIn from "./components/logInScreen/log-in";

// homeScreen
import PostCodeConversion from "./components/homeScreen/postcode-api";
import UserInfo from "./components/homeScreen/user-information";

// accountScreen
// import AccountIndividualListing from "./components/accountScreen/accountPage/components/accountIndividualListing/account-individual-listing";
import AccountPage from "./components/accountScreen/accountPage/account-page";

// individualListingScreen
import IndividualListing from "./components/individualListingScreen/individual-listing";

// listingsScreen
import ListingsApi from "./components/listingsScreen/components/listings-api";
import PageCount from "./components/navigationBar/page-count";

// waitingScreen
import ApiWaiting from "./components/waitingScreen/wait-for-api";

// registrationScreen
import RegistrationPage from "./components/registrationScreen/registration";
import RegisterPassword from "./components/appScreen/register-password";

// appScreen
import NoResult from "./components/appScreen/no-results";
import IconHolder from "./components/appScreen/icon-holder";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

// API
import { fetchApiData } from "./fetchApi";

class App extends Component {
  state = {
    agentAddress: "",
    agentLogo: "",
    agentName: "",
    agentPhone: "",
    agentPostcode: "",
    autoCompleteListArea: "",
    bedrooms: 0,
    category: "",
    country: "",
    county: "",
    displayableAddress: "",
    enquiryType: "",
    pageArray: [0],
    image: ["", ""],
    image645430Url: "",
    imageUrl: "",
    incode: "",
    individualListingId: "",
    latitude: 0,
    listing: ["", ""],
    listingDate: "",
    listingId: "",
    userSavedListingId: "",
    longitude: 0,
    longLat: [0, 0],
    maxPages: "",
    newEmail: "",
    newForename: "",
    newListingIdArray: [],
    newSurname: "",
    numBathrooms: "",
    numBedrooms: "",
    numberBeds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    numFloors: "",
    numReception: "",
    orderBy: "descending",
    originalImage: "",
    outcode: "",
    page: 1,
    postCode: null,
    postTown: "",
    price: "",
    propertiesListArea: "",
    propertyNumber: "",
    propertyType: "",
    resultCount: "",
    screenMode: 1,
    searchTime: "",
    showFlag: [],
    sortBy: "age",
    status: "",
    streetName: "",
    thumbNailImage: "",
    thumbnailUrl: "",
    title: "",
    viewCount: "",
  };

  setSavedListingState = (savedListingData) => {
    this.setState({
      agentAddress: savedListingData.agentAddress,
      agentLogo: savedListingData.agentLogo,
      agentName: savedListingData.agentAddress,
      agentPhone: savedListingData.agentAddress,
      agentPostcode: savedListingData.agentAddress,
      category: savedListingData.agentAddress,
      country: savedListingData.agentAddress,
      county: savedListingData.agentAddress,
      displayableAddress: savedListingData.agentAddress,
      image645430Url: savedListingData.image645430Url,
      incode: savedListingData.incode,
      latitude: savedListingData.latitude,
      listingDate: savedListingData.listingDate,
      listingId: savedListingData.listingId,
      longLat: savedListingData.longLat,
      longitude: savedListingData.longitude,
      numBathrooms: savedListingData.numBathrooms,
      numBedrooms: savedListingData.numBedrooms,
      numFloors: savedListingData.numFloors,
      numRecepts: savedListingData.numRecepts,
      originalImage: savedListingData.originalImage,
      outcode: savedListingData.outcode,
      postTown: savedListingData.postTown,
      price: savedListingData.price,
      propertyNumber: savedListingData.propertyNumber,
      propertyType: savedListingData.propertyType,
      showFlag: savedListingData.showFlag,
      status: savedListingData.status,
      streetName: savedListingData.streetName,
      thumbnailUrl: savedListingData.thumbnailUrl,
      title: savedListingData.title,
      viewCount: savedListingData.viewCount,
    });
  };

  setScreenMode = (mode) => {
    this.setState({ screenMode: mode });
  };

  setPageNumber = (mode) => {
    this.setState({
      page: mode,
      screenMode: "Waiting Screen",
      propertiesListArea: this.state.propertiesListArea,
    });
  };

  setSortBy = (term, term2) => {
    this.setState({
      sortBy: term,
      orderBy: term2,
      screenMode: "Waiting Screen",
    });
  };

  setEnquiryType = (enquiry) => {
    this.setState({ enquiryType: enquiry });
  };

  setBedroomNum = (beds) => {
    this.setState({ bedrooms: beds });
  };

  setPostCode = (code) => {
    this.setState({ postcode: code });
  };

  setListingId = (id) => {
    this.setState({ individualListingId: id });
  };

  setMyIndex = (ind) => {
    this.setState({ myIndex: ind });
  };

  setNewEmail = (newEmail) => {
    this.setState({ newEmail: newEmail });
  };

  setNewForename = (newForename) => {
    this.setState({ newForename: newForename });
  };

  setNewSurname = (newSurname) => {
    this.setState({ newSurname: newSurname });
  };

  setPageArray = (page) => {
    this.setState({ pageArray: page });
  };

  setShowFlag = (showFlag, userSavedListingId) => {
    this.setState({
      showFlag: showFlag,
      userSavedListingId: userSavedListingId,
    });
  };

  getsavedlisting = async (e) => {
    const result = await axios.get(
      // `https://homefinderappnow.co.uk/addenquiry/${this.state.newEmail}/${this.state.postcode}/${this.state.propertiesListArea}`
      `http://localhost:5000/getsavedlisting/${this.state.newEmail}`
    );
    this.setState({
      userSavedListingId: result.data.map((e) => e.listing_id)
    });
    // console.log(result.data.map((e) => e.listing_id));
  };

  submitEnquiry = async (e) => {
    const result = await axios.get(
      // `https://homefinderappnow.co.uk/addenquiry/${this.state.newEmail}/${this.state.postcode}/${this.state.propertiesListArea}`
      `http://localhost:5000/addenquiry/${this.state.newEmail}/${this.state.postcode}/${this.state.propertiesListArea}`
    );
  };

  updateShowFlagState = () => {
    this.showFlagArray();
    let result = this.showFlagArray();
    console.log(result);
    this.setState({
      showFlag: result.showFlagArray,
      newListingIdArray: result.newListingIdArray,
    });
  };

  showFlagArray = () => {
    var showFlagArray = [];
    const one = 1;
    const zero = 0;

    var newListingIdArray = [];

    for (var i = 0; i < this.state.listingId.length; i++) {
      if (this.state.userSavedListingId.includes(this.state.listingId[i]) === true) {
        showFlagArray.push(one);
        newListingIdArray.push(this.state.listingId[i]);
      } else {
        showFlagArray.push(zero);
        newListingIdArray.push(this.state.listingId[i]);
      }
    }
    return { showFlagArray, newListingIdArray };
  };

  setNextPage = () => {
    this.setState({
      page: this.state.page + 1,
      screenMode: "Waiting Screen",
      propertiesListArea: this.state.propertiesListArea,
    });
  };

  setPreviousPage = () => {
    this.setState({
      page: this.state.page - 1,
      screenMode: "Waiting Screen",
      propertiesListArea: this.state.propertiesListArea,
    });
  };

  setPropertiesListArea = (term) => {
    this.setState({
      screenMode: "Waiting Screen",
      postcode: null,
      propertiesListArea: term,
      outcode: null,
    });
  };

  setSearchTime = (time) => {
    this.setState({ searchTime: time });
  };

  getPostCode = (postTown) => {
    this.setState({
      autoCompleteListArea: postTown,
    });
  };

  resultCount = (resultCount) => {
    if (resultCount === 0) {
      this.setState({ screenMode: "No Result" });
      console.log("No Result");
    } else {
      this.setState({ screenMode: "Listings Screen" });
      console.log("Listings Screen");
    }
  };

  async processData() {
    try {
      // console.log("Start");

      const result = await fetchApiData(
        this.state.propertiesListArea,
        this.state.bedrooms,
        this.state.page,
        this.state.enquiryType,
        this.state.sortBy,
        this.state.orderBy
      );

      // console.log("mid_0");

      // console.log(result);

      // console.log("mid_1");

      this.setState({
        agentAddress: result.data.listing.map((e) => e.agent_address),
        agentLogo: result.data.listing.map((e) => e.agent_logo),
        agentName: result.data.listing.map((e) => e.agent_name),
        agentPhone: result.data.listing.map((e) => e.agent_phone),
        agentPostcode: result.data.listing.map((e) => e.agent_postcode),
        branchId: result.data.listing.map((e) => e.branch_id),
        bullet: result.data.listing.map((e) => e.bullet),
        category: result.data.listing.map((e) => e.category),
        companyId: result.data.listing.map((e) => e.company_id),
        country: result.data.listing.map((e) => e.country),
        countryCode: result.data.listing.map((e) => e.country_code),
        county: result.data.listing.map((e) => e.county),
        description: result.data.listing.map((e) => e.description),
        detailsUrl: result.data.listing.map((e) => e.details_url),
        displayableAddress: result.data.listing.map(
          (e) => e.displayable_address
        ),
        document: result.data.listing.map((e) => e.document),
        epc: result.data.listing.map((e) => e.epc),
        featuredType: result.data.listing.map((e) => e.featured_type),
        firstPublishedDate: result.data.listing.map(
          (e) => e.first_published_date
        ),
        floorPlan: result.data.listing.map((e) => e.floor_plan),
        furnishedState: result.data.listing.map((e) => e.furnished_state),
        groupId: result.data.listing.map((e) => e.group_id),
        image150113Url: result.data.listing.map((e) => e.image15_01_13_url),
        image354255Url: result.data.listing.map((e) => e.image_35_42_55_url),
        image5038Url: result.data.listing.map((e) => e.image_50_38_url),
        image645430Url: result.data.listing.map((e) => e.image_64_54_30_url),
        image8060Url: result.data.listing.map((e) => e.image_80_60_uUrl),
        imageCaption: result.data.listing.map((e) => e.image_caption),
        imageUrl: result.data.listing.map((e) => e.image_url),
        images: result.data.listing.map((e) => e.images),
        incode: result.data.listing.map((e) => e.incode),
        isPremiumListing: result.data.listing.map((e) => e.is_premium_listing),
        lastPublishedDate: result.data.listing.map(
          (e) => e.last_published_date
        ),
        lastSaleDate: result.data.listing.map((e) => e.last_sale_date),
        lastSalePrice: result.data.listing.map((e) => e.last_sale_price),
        latitude: result.data.listing.map((e) => e.latitude),
        listingDate: result.data.listing.map((e) => e.listing_date),
        listingId: result.data.listing.map((e) => e.listing_id),
        listingStatus: result.data.listing.map((e) => e.listing_status),
        locationIsApproximate: result.data.listing.map(
          (e) => e.location_is_approximate
        ),
        longitude: result.data.listing.map((e) => e.longitude),
        numBathrooms: result.data.listing.map((e) => e.num_bathrooms),
        numBedrooms: result.data.listing.map((e) => e.num_bedrooms),
        numFloors: result.data.listing.map((e) => e.num_floors),
        numReception: result.data.listing.map((e) => e.num_reception),
        originalImage: result.data.listing.map((e) => e.original_image),
        otherImage: result.data.listing.map((e) => e.other_image),
        outcode: result.data.listing.map((e) => e.outcode),
        postTown: result.data.listing.map((e) => e.post_town),
        price: result.data.listing.map((e) => e.price),
        priceChange: result.data.listing.map((e) => e.price_change),
        priceModifier: result.data.listing.map((e) => e.price_modifier),
        propertyBadge: result.data.listing.map((e) => e.property_badge),
        propertyId: result.data.listing.map((e) => e.property_id),
        propertyNumber: result.data.listing.map((e) => e.property_number),
        propertyType: result.data.listing.map((e) => e.property_type),
        shortDescription: result.data.listing.map((e) => e.short_description),
        status: result.data.listing.map((e) => e.status),
        streetName: result.data.listing.map((e) => e.street_name),
        thumbnailUrl: result.data.listing.map((e) => e.thumbnail_url),
        title: result.data.listing.map((e) => e.title),
        viewCount: result.data.listing.map((e) => e.view_count),
        viewCount30Day: result.data.listing.map((e) => e.view_count_30_day),
        virtualTour: result.data.listing.map((e) => e.virtual_tour),
        longLat: result.data.listing.map((e) => [e.longitude, e.latitude]),
        maxPages: Math.round(result.data.result_count / 40),
        resultCount: result.data.result_count,
      });

      // 
    var populatePageArray = [];
    for (var i = 1; i <= this.state.maxPages; i++) {
      populatePageArray.push(i);
    }
    this.setState({ pageArray: populatePageArray });

    this.updateShowFlagState();

    this.resultCount(this.state.resultCount);

  } catch (error) {
      console.log("error:", error);
    } 
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.newEmail != this.state.newEmail) {
      this.getsavedlisting();
    }

    if (
      prevState.propertiesListArea !== this.state.propertiesListArea || // if there is a change in propertiesListArea
      (prevState.propertiesListArea === this.state.propertiesListArea &&
        prevState.searchTime < this.state.searchTime) || // if there is a change in propertiesListArea
      (prevState.propertiesListArea === this.state.propertiesListArea && // if the page has changed
        prevState.page !== this.state.page &&
        this.state.screenMode === "Waiting Screen") ||
      prevState.sortBy !== this.state.sortBy
    ) {
      this.processData();
    }
  }

  render() 
  {
    return (
      <div className="content backgroundimage">
        <header className="app-header">
          <IconHolder className="logo" />
          <NavBar
            setScreenMode={this.setScreenMode}
            email={this.state.newEmail}
          />
        </header>
        {this.state.screenMode === 0 ? (
          <p></p>
        ) : this.state.screenMode === 5 ? (
          <p></p>
        ) : this.state.screenMode === 6 ? (
          <p></p>
        ) : (
          <></>
        )}
        <div className="center-container">
        <div className="home-page">
          {this.state.screenMode === 0 ? (
            <LogIn
              setScreenMode={this.setScreenMode}
              setLogin={this.setLogin}
              sendLogin={this.sendLogin}
              setNewEmail={this.setNewEmail}
            />
          ) : this.state.screenMode === 1 ? (
            <>
              <PostCodeConversion
                email={this.state.newEmail}
                enquiryType={this.state.enquiryType}
                getPostCode={this.getPostCode}
                setPostCode={this.setPostCode}
                setPropertiesListArea={this.setPropertiesListArea}
                setSearchTime={this.setSearchTime}
                setScreenMode={this.setScreenMode}
                setSearchTerm={this.setSearchTerm}
                setShowFlag={this.setShowFlag}
                submitEnquiry={this.submitEnquiry}
                setNoResult={this.setNoResult}
                page={this.setPageNumber}
              />
              <UserInfo
                setScreenMode={this.setScreenMode}
                setEnquiryType={this.setEnquiryType}
                setBedroomNum={this.setBedroomNum}
                numberBeds={this.state.numberBeds}
                longLat={this.state.longLat}
                submitEnquiry={this.submitEnquiry}
                enquiryType={this.state.enquiryType}
                bedrooms={this.state.bedrooms}
              />
            </>
          ) : this.state.screenMode === "Listings Screen" ? (
            <div className="123">
              <GoUserInfo setScreenMode={this.setScreenMode} />
              <SortListing
                sortBy={this.setSortBy}
                sort={this.state.sortBy}
                order={this.state.orderBy}
              />
              <PrevPageButton setPreviousPage={this.setPreviousPage} />
              <NextPageButton setNextPage={this.setNextPage} />
              <PageCount
                pageNumber={this.state.page}
                maxPages={this.state.maxPages}
                pageArray={this.state.pageArray}
                setPageNumber={this.setPageNumber}
              />
              <ListingsApi 
                displayableAddress={this.state.displayableAddress}
                newEmail={this.state.newEmail}
                imageUrl={this.state.imageUrl}
                listingId={this.state.listingId}
                userSavedListingId={this.state.userSavedListingId}
                numBedrooms={this.state.numBedrooms}
                originalImage={this.state.originalImage}
                price={this.state.price}
                resultCount={this.state.resultCount}
                setListingId={this.setListingId}
                setMyIndex={this.setMyIndex}
                setPageArray={this.setPageArray}
                setScreenMode={this.setScreenMode}
                showFlag={this.state.showFlag}
                status={this.state.status}
              /> 
            </div>
          ) : this.state.screenMode === "Individual Listing Screen" ? (
            < /*className="456"*/>
              <GoUserInfo
                setScreenMode={this.setScreenMode}
                page={this.setPageNumber}
              />
              <GoListingPage setScreenMode={this.setScreenMode} />
              {
                <IndividualListing
                  agentAddress={this.state.agentAddress}
                  agentLogo={this.state.agentLogo}
                  agentName={this.state.agentName}
                  agentPhone={this.state.agentPhone}
                  agentPostcode={this.state.agentPostcode}
                  displayableAddress={this.state.displayableAddress}
                  bedrooms={this.state.bedrooms}
                  email={this.state.newEmail}
                  image={this.state.thumbNailImage}
                  image645430Url={this.state.image645430Url}
                  individualListingId={this.state.individualListingId}
                  listing={this.state.listing}
                  listingId={this.state.listingId}
                  listingType={this.state.enquiryType}
                  longLat={this.state.longLat}
                  myIndex={[this.state.myIndex]}
                  originalImage={this.state.originalImage}
                  price={this.state.price}
                  setListingId={this.setListingId}
                  setScreenMode={this.setScreenMode}
                  showFlag={this.state.showFlag}
                  status={this.state.status}
                />
              }
            </>
          ) : this.state.screenMode === 5 ? (
            <RegistrationPage
              setScreenMode={this.setScreenMode}
              setNewEmail={this.setNewEmail}
              setNewForename={this.setNewForename}
              setNewSurname={this.setNewSurname}
            />
          ) : this.state.screenMode === 6 ? (
            <RegisterPassword
              setScreenMode={this.setScreenMode}
              newEmail={this.state.newEmail}
              newForename={this.state.newForename}
              newSurname={this.state.newSurname}
            />
          ) : this.state.screenMode === "No Result" ? (
            <div className="502">
              <GoUserInfo
                setScreenMode={this.setScreenMode}
                page={this.setPageNumber}
              />
              <NoResult />
            </div>
          ) : this.state.screenMode === "Saved Properties" ? (
            <div className="510">
              <GoUserInfo
                setScreenMode={this.setScreenMode}
                page={this.setPageNumber}
              />
              <AccountPage
                email={this.state.newEmail}
                setScreenMode={this.setScreenMode}
                setListingId={this.setListingId}
                setMyIndex={this.setMyIndex}
                setSavedListingState={this.setSavedListingState}
              />
            </div>
          ) : this.state.screenMode === "Account Individual Listing Screen" ? (
            <div className="524">
              {
                <IndividualListing
                  agentAddress={this.state.agentAddress}
                  agentLogo={this.state.agentLogo}
                  agentName={this.state.agentName}
                  agentPhone={this.state.agentPhone}
                  agentPostcode={this.state.agentPostcode}
                  displayableAddress={this.state.displayableAddress}
                  bedrooms={this.state.bedrooms}
                  email={this.state.newEmail}
                  image={this.state.thumbnailImage}
                  image645430Url={this.state.image645430Url}
                  individualListingId={this.state.individualListingId}
                  listing={this.state.listing}
                  listingId={this.state.listingId}
                  listingType={this.state.enquiryType}
                  listingStatus={this.state.status}
                  longLat={this.state.longLat}
                  myIndex={[this.state.myIndex]}
                  originalImage={this.state.originalImage}
                  price={this.state.price}
                  setListingId={this.setListingId}
                  setScreenMode={this.setScreenMode}
                  showFlag={this.state.showFlag}
                  status={this.state.status}
                />
              }
            </div>
          ) : this.state.screenMode === "Waiting Screen" ? (
            <div> {
            <ApiWaiting /> 
            }
            </div>
          ) : (
            <p>popo{}</p>
          )}
          </div>          
        </div>
      </div>
    );
  };
}

export default App;
