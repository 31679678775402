import axios from "axios";

export const getListingDataFromSql = async (email) => {
    
    const result = await axios.get(
        `http://localhost:5000/savedlistings/${email}`,        
        {}

      );
      return result;
};

export default getListingDataFromSql