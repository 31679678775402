import axios from "axios";

export async function fetchApiData(propertiesListArea, bedrooms, page, enquiryType, sortBy, orderBy) {

    const result = await axios.get(
        `http://localhost:5000/getApiData/${propertiesListArea}/${bedrooms}/${page}/${enquiryType}/${sortBy}/${orderBy}`,        
        {}
      );
      return result;


}