import React, { Component } from 'react';
import SaveStar from '../listingsScreen/components/listing-save';
import UkMap from "../individualListingScreen/components/uk-map";
import IndividualImage from '../individualListingScreen/components/individual-carousel';
import HousePrice from "../listingsScreen/components/price";
import './individual-listing.css';

class IndividualListing extends Component {

    render() { 
        return ( 
        <div className='individual-listing-page'>
        <p className='ilp-dialogue'>this is the individual listing {this.props.individualListingId} {this.props.myIndex}</p> 
        <p className='ilp-address'>{this.props.displayableAddress[this.props.myIndex]}</p>
        <div className='ilp-price'>
        <HousePrice price={this.props.price[this.props.myIndex]} status={this.props.status[this.props.myIndex]}/>
        </div>
        <div className='ilp-image'>
        <IndividualImage originalImage={this.props.originalImage[this.props.myIndex]}/>
        </div>
        <div className='ilp-map'>
        <UkMap longLat={this.props.longLat[this.props.myIndex]} />
        </div>
        <div className='ilp-save-star'>
        <SaveStar 
                listingId={this.props.listingId[this.props.myIndex]}
                email={this.props.email}
                showFlag={this.props.showFlag[this.props.myIndex]}
        />
        </div>
        </div>
        );
    }
}

export default IndividualListing;