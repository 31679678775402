import React, { Component } from "react";
import { fromLonLat } from "ol/proj";
import "ol/ol.css";
import { RMap, ROSM, RLayerVector, RStyle } from "rlayers";
import Mapfeature from "../components/map-feature";

// const center = fromLonLat([0.1278, 51.5074]);
// const center = fromLonLat([0.1278, 51.5074]);

class UkMap extends Component {
  render() {
    return (
      <RMap className="r-map"
        // width={"33vh"}
        // height={"30vh"}
        initial={{ center: fromLonLat(this.props.longLat), zoom: 15 }}
      >
        <ROSM />
        <RLayerVector zIndex={10}>
          <RStyle.RStyle>
            <RStyle.RIcon
              src={
                "https://upload.wikimedia.org/wikipedia/commons/d/d1/Google_Maps_pin.svg"
              }
              anchor={[0.5, 0.8]}
            />
          </RStyle.RStyle>
          <Mapfeature longLat={this.props.longLat} />
        </RLayerVector>
      </RMap>
    );
  }
}

export default UkMap;
