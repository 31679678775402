import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";

class SortListing extends Component {
  state = {
    options: [],
    optionsOrder: [],
    ordering: "",
    sort: "",
    order: "",
    currentOption: "",
  };

  componentDidMount() {
    if (this.props.sort === "age" && this.props.order === "ascending") {
      this.setState({
        options: ["price", "pricechange", "viewcount"],
        optionsOrder: [
          "age - High to Low",
          "price - High to Low",
          "price - Low to High",
          "pricechange - High to Low",
          "pricechange - Low to High",
          "viewcount - High to Low",
          "viewcount - Low to High",
        ],
        currentOption: "age - Low to High",
      });
    }

    if (this.props.sort === "age" && this.props.order === "descending") {
      this.setState({
        options: ["price", "pricechange", "viewcount"],
        optionsOrder: [
          "age - Low to High",
          "price - High to Low",
          "price - Low to High",
          "pricechange - High to Low",
          "pricechange - Low to High",
          "viewcount - High to Low",
          "viewcount - Low to High",
        ],
        currentOption: "age - High to Low",
      });
    }

    if (this.props.sort === "price" && this.props.order === "ascending") {
      this.setState({
        options: ["price", "pricechange", "viewcount"],
        optionsOrder: [
          "age - High to Low",
          "age - Low to High",
          "price - High to Low",
          "pricechange - High to Low",
          "pricechange - Low to High",
          "viewcount - High to Low",
          "viewcount - Low to High",
        ],
        currentOption: "price - Low to High",
      });
    }

    if (this.props.sort === "price" && this.props.order === "descending") {
      this.setState({
        options: ["price", "pricechange", "viewcount"],
        optionsOrder: [
          "age - High to Low",
          "age - Low to High",
          "price - Low to High",
          "pricechange - High to Low",
          "pricechange - Low to High",
          "viewcount - High to Low",
          "viewcount - Low to High",
        ],
        currentOption: "price - High to Low",
      });
    }

    if (this.props.sort === "pricechange" && this.props.order === "ascending") {
      this.setState({
        options: ["price", "pricechange", "viewcount"],
        optionsOrder: [
          "age - High to Low",
          "age - Low to High",
          "price - High to Low",
          "price - Low to High",
          "pricechange - High to Low",

          "viewcount - High to Low",
          "viewcount - Low to High",
        ],
        currentOption: "pricechange - Low to High",
      });
    }
    if (
      this.props.sort === "pricechange" &&
      this.props.order === "descending"
    ) {
      this.setState({
        options: ["price", "pricechange", "viewcount"],
        optionsOrder: [
          "age - High to Low",
          "age - Low to High",
          "price - High to Low",
          "price - Low to High",

          "pricechange - Low to High",
          "viewcount - High to Low",
          "viewcount - Low to High",
        ],
        currentOption: "pricechange - High to Low",
      });
    }

    if (this.props.sort === "viewcount" && this.props.order === "ascending") {
      this.setState({
        options: ["price", "pricechange", "viewcount"],
        optionsOrder: [
          "age - High to Low",
          "age - Low to High",
          "price - High to Low",
          "price - Low to High",
          "pricechange - High to Low",
          "pricechange - Low to High",
          "viewcount - High to Low",
        ],
        currentOption: "viewcount - Low to High",
      });
    }

    if (this.props.sort === "viewcount" && this.props.order === "descending") {
      this.setState({
        options: ["price", "pricechange", "viewcount"],
        optionsOrder: [
          "age - High to Low",
          "age - Low to High",
          "price - High to Low",
          "price - Low to High",
          "pricechange - High to Low",
          "pricechange - Low to High",
          "viewcount - Low to High",
        ],
        currentOption: "viewcount - High to Low",
      });
    }
  }

  sendOption = (item) => {
    let myItem = item;

    if (myItem === "age - High to Low") {
      this.setState({ sort: "age", order: "descending" }, () => {
        this.props.sortBy(this.state.sort, this.state.order);
      });
    }
    if (myItem === "age - Low to High") {
      this.setState({ sort: "age", order: "ascending" }, () => {
        this.props.sortBy(this.state.sort, this.state.order);
      });
    }
    if (myItem === "price - High to Low") {
      this.setState({ sort: "price", order: "descending" }, () => {
        this.props.sortBy(this.state.sort, this.state.order);
      });
    }
    if (myItem === "price - Low to High") {
      this.setState({ sort: "price", order: "ascending" }, () => {
        this.props.sortBy(this.state.sort, this.state.order);
      });
    }
    if (myItem === "pricechange - High to Low") {
      this.setState({ sort: "pricechange", order: "descending" }, () => {
        this.props.sortBy(this.state.sort, this.state.order);
      });
    }
    if (myItem === "pricechange - Low to High") {
      this.setState({ sort: "pricechange", order: "ascending" }, () => {
        this.props.sortBy(this.state.sort, this.state.order);
      });
    }
    if (myItem === "viewcount - High to Low") {
      this.setState({ sort: "viewcount", order: "descending" }, () => {
        this.props.sortBy(this.state.sort, this.state.order);
      });
    }
    if (myItem === "viewcount - Low to High") {
      this.setState({ sort: "viewcount", order: "ascending" }, () => {
        this.props.sortBy(this.state.sort, this.state.order);
      });
    }
  };

  render() {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Sort By : {this.state.currentOption}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {this.state.optionsOrder.map((item, key) => (
            <Dropdown.Item
              href="#/action-1"
              key={item}
              onClick={() => {
                this.sendOption(item);
              }}
            >
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default SortListing;
