import {Button} from "react-bootstrap";
const GoListingPage = (props) => {
  return (
    <Button
      id=""
      onClick={() => {
        props.setScreenMode("Listings Screen");
      }}
    >
      Back to Listings Page
    </Button>
  );
};

export default GoListingPage;
