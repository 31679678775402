import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import BlueStar from "../icon/star.svg";
import LoginIcon from "../icon/login.svg";
import HomeIcon from "../icon/home.svg";

class NavBar extends Component {
  state = {
    logIn: "Sign In / Register",
    email: "",
  };

  componentDidUpdate() {
    // console.log('component did update')
  };

  render() {
    return (
      <Nav className="nav-bar">
        <Nav.Item
          className="home-link"
          onClick={() => this.props.setScreenMode(1)}
        >
          <img
            className="home-link-icon"
            src={HomeIcon}
            width=""
            height=""
            alt="React Bootstrap logo"
          />
          <Nav.Link>Home Page</Nav.Link>
        </Nav.Item>
        <Nav.Item
          className="log-in"
          onClick={() => {
            this.props.setScreenMode(0);
          }}
        >
          <img
            className="log-in-icon"
            src={LoginIcon}
            width=""
            height=""
            alt="React Bootstrap logo"
          />
          <Nav.Link>
            {this.props.email === "" ? "Log In" : this.props.email}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="prop" onClick={() => this.props.setScreenMode("Saved Properties")}>
          <img
            className="prop-icon"
            src={BlueStar}
            width=""
            height=""
            alt="React Bootstrap logo"
          />
          <Nav.Link>Saved Properties</Nav.Link>
        </Nav.Item>
      </Nav>
    );
  }
}

export default NavBar;
