import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class NextPageButton extends Component {
    render() { 
        return <Button onClick={() => this.props.setNextPage()} > Next</Button>

    };
}
 
export default NextPageButton;