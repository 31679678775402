import React from "react";
import { Button } from "react-bootstrap";

const SubmitSearchButton = (props) => {

    return (
          <Button 
            onClick={() => {
              this.sendSearchTerm(props.searchOutcode);
              this.sendSearchTime(props.currentTime);
              console.log(props, 'this is the props');
            }}
          >
            Submit Search
          </Button>

    );
};

export default SubmitSearchButton;