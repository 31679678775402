import React, { Component } from "react";
// import axios from "axios";
// import AccountListingsPage from ".././accountListingPage/account-listings-page";
import ListingsPage from "../../listingsScreen/listings-screen";
import getListingDataFromSql from "../../../listingQuery"

class AccountPage extends Component {
  state = {
    agentAddress: "",
    agentLogo: "",
    agentName: "",
    agentPhone: "",
    agentPostcode: "",
    category: "",
    county: "",
    country: "",
    data: {},
    displayableAddress: "",
    image645430Url: "",
    incode: "",
    listingDate: "",
    listingId: [],
    latitude: 0,
    longitude: 0,
    longLat: [0, 0],
    numBathrooms: 0,
    numBedrooms: 0,
    numFloors: 0,
    numRecepts: 0,
    originalImage: [],
    outcode: "",
    postTown: "",
    price: 0,
    propertyNumber: "",
    propertyType: "",
    showFlag: 0,
    status: "",
    streetName: "",
    thumbnailUrl: "",
    title: "",
    viewCount: 0,
  };
  
  
  setShowFlag = () => {
    this.setState({ showFlag: 1 });
  };



  capture = (result) => {
      const dataEntries = result.data;
  
    this.setState({
      agentAddress: dataEntries.map((entry) => entry.agent_address),
      agentLogo: dataEntries.map((entry) => entry.agent_logo),
      agentName: dataEntries.map((entry) => entry.agent_name),
      agentPhone: dataEntries.map((entry) => entry.agent_phone),
      agentPostcode: dataEntries.map((entry) => entry.agent_postcode),
      category: dataEntries.map((entry) => entry.category),
      country: dataEntries.map((entry) => entry.country),
      county: dataEntries.map((entry) => entry.county),
      displayableAddress: dataEntries.map((entry) => entry.displayable_address),
      image645430Url: dataEntries.map((entry) => entry.image_645_430_url),
      incode: dataEntries.map((entry) => entry.incode),
      latitude: dataEntries.map((entry) => entry.latitude),
      listingDate: dataEntries.map((entry) => entry.listing_date),
      listingId: dataEntries.map((entry) => entry.listing_id),
      longitude: dataEntries.map((entry) => entry.longitude),
      longLat: dataEntries.map((entry) => [entry.longitude, entry.latitude]),
      numBathrooms: dataEntries.map((entry) => entry.num_bathrooms),
      numBedrooms: dataEntries.map((entry) => entry.num_bedrooms),
      numFloors: dataEntries.map((entry) => entry.num_floors),
      numRecepts: dataEntries.map((entry) => entry.num_recepts),
      originalImage: dataEntries.map((entry) => entry.images.split(",")),
      outcode: dataEntries.map((entry) => entry.outcode),
      postTown: dataEntries.map((entry) => entry.post_town),
      price: dataEntries.map((entry) => entry.price),
      propertyNumber: dataEntries.map((entry) => entry.property_number),
      propertyType: dataEntries.map((entry) => entry.property_type),
      showFlag: dataEntries.map((entry) => entry.show_flag),
      status: dataEntries.map((entry) => entry.status),
      streetName: dataEntries.map((entry) => entry.street_name),
      thumbnailUrl: dataEntries.map((entry) => entry.thumbnail_url),
      title: dataEntries.map((entry) => entry.title),
      viewCount: dataEntries.map((entry) => entry.view_count),
      
    });
  
    this.props.setSavedListingState(this.state);
  };
  

  getData = async () => {
    let result = await getListingDataFromSql(this.props.email)
    this.capture(result);
    console.log(result, 'this is the result')
  };

  componentDidMount() {
    // this.setShowFlag();
    this.getData();
  }

  render() { console.log(this.state)
    return (
      <div>
        {this.state.listingId.map((item, index) => {
          return (
            // <div key={item.id}>
            <div key={item.listingId}>
            <ListingsPage
              displayableAddress={this.state.displayableAddress[index]}
              listingId={item}
              listingType={this.state.status[index]}
              imageUrl={this.state.image645430Url[index]}
              price={this.state.price[index]}
              numBedrooms={this.state.numBedrooms[index]}
              setScreenMode={this.props.setScreenMode}
              setListingId={this.props.setListingId}
              setMyIndex={this.props.setMyIndex}
              individualListingId={this.state.listingId[index]}
              myIndex={[index]}
              email={this.props.email}
              listingStatus={this.state.status[index]} 
              originalImage={this.state.originalImage}
              thumbnailUrl={this.state.image645430Url[index]}
              showFlag={this.state.showFlag[index]}
            />
            </div>
          );
        })}
      </div>
    );
  }
}

export default AccountPage;
