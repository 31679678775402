// import { toStringHDMS } from 'ol/coordinate';
import React, { Component } from "react";
import Button from "react-bootstrap/Button";

class PageCountButton extends Component {
  state = {
    variant: "secondary",
    page: "",
    pageNumber: "",
  };

  componentDidMount() {
    if (this.props.page === 0) {
      this.setState({ variant: "primary" });
    }

    if (this.props.page === this.props.pageNumber) {
      this.setState({ variant: "primary" });
    }
  }

  render() {
    return (
      <Button
        variant={this.state.variant}
        onClick={() => {
          this.props.setPageNumber(this.props.page);
        }}
      >
        {this.props.page}
      </Button>
    );
  }
}

export default PageCountButton;
