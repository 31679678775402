import React, { Component } from "react";
import "ol/ol.css";
import {RFeature, ROverlay} from "rlayers";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";

class Mapfeature extends Component {
  coords = {
    origin: this.props.longLat
  };
  render() {

    return (
      <RFeature
        geometry={new Point(fromLonLat(this.coords.origin))}
        onClick={(e) =>
          e.map.getView().fit(e.target.getGeometry().getExtent(), {
            duration: 250,
            maxZoom: 15,
          })
        }
      >
        <ROverlay className="example-overlay">
          {/* Pin */}
          <br />
          {/* <em>&#11017; </em> */}
        </ROverlay>
      </RFeature>
    );
  }
}

export default Mapfeature;
