import React, { Component } from "react";

class ApiWaiting extends React.Component {
  render() {
    return (
      <div className="gears">
        <p></p>

        <div id="wrapper">
          <div className="gears" id="one-gear">
            <div className="gears-container">
              <div className="gear-rotate"></div>
            </div>

            <div className="message">
              <h1>Loading</h1>
              Please wait while we get the results.....
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApiWaiting;
