import React, { Component } from "react";

class HousePrice extends Component {
  render() {
    if (this.props.status === 'for_sale') {
      return <p>{new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(this.props.price)} </p>;
    } else {
      return <p>Weekly Rent: {new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(this.props.price)}</p>
    }

  }
}

export default HousePrice;
