import React, { Component } from "react";
import HouseImages from "./components/house-images";
import Address from "./components/address";
import ListingType from "./components/listing-type";
import HousePrice from "./components/price";
import Bedrooms from "./components/bedrooms";
import ListingId from "./components/listingId";
import SaveStar from "./components/listing-save";
import { Button } from "react-bootstrap";

class ListingsPage extends Component {
  render() {
    return ( 
      <div>
        <div className="listing-page">
          <div className="flex-listing-item address">
            <Address displayableAddress={this.props.displayableAddress} />
          </div>
          <div className="flex-listing-item price">
            <HousePrice price={this.props.price} status={this.props.status} />
          </div>
          <div className="flex-listing-item bedrooms">
            <Bedrooms numBedrooms={this.props.numBedrooms} />
          </div>
          <div className="flex-listing-item item-d">
            <ListingType status={this.props.status} />
          </div>
          <div className="flex-listing-item image">
            <HouseImages imageUrl={this.props.imageUrl} />
          </div>
          <div className="flex-listing-item star-container">
            <SaveStar
              listingId={this.props.listingId}
              email={this.props.email}
              showFlag={this.props.showFlag}
            />
          </div>
          <Button
            className="item-d"
            onClick={() => {
              this.props.setScreenMode("Individual Listing Screen");
              this.props.setListingId(this.props.listingId);
              this.props.setMyIndex(this.props.myIndex);
            }}
          >
            {" "}
            {this.props.listingId}, {this.props.myIndex} view property
          </Button>
          <div className="flex-listing-item listing-id">
            <ListingId listingId={this.props.listingId} />
          </div>
        </div>
      </div>
    );
  }
}

export default ListingsPage;
