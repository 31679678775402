import React, { Component } from 'react';
import PageCountButton from './page-count-button';

class PageCount extends Component {

    render() {
        return (
          <div >
            {this.props.pageArray.map((item, index) => {
              return <PageCountButton key={index} page={item} setPageNumber={this.props.setPageNumber} pageNumber={this.props.pageNumber}/> ;
            })}
          </div>
        );
      }
    }
export default PageCount;
