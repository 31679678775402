import React, { Component } from "react";
// import axios from "axios";
import { Button } from "react-bootstrap";
import { currentTime } from "./search-time";
import searchPostCode from "./searchPostCode"
import SubmitSearchButton from "./submit-search-button"

class PostCodeConversion extends Component {
  state = {
    screenMode: 0,
    postcode: "",
    postcodeYesNo: "",
    outCode: [],
    towns: [],
    searchTerm: "",
    searchOutcode: "",
    zooplaSearchTerm: "",
    autoCompleteListArea: "",
    enquiryType: "",
    searchTime:""
  };

  //this currently passes all the inputs to the zoopla api and to the sql query. we must simplify. have this only send to sql and have it only send to the zoopla api on the search.
  postcodeInput = (e) => {
    this.setState({
      postcode: e.target.value.toUpperCase(),
      searchTerm: e.target.value.toUpperCase(),
    });
  };

  is_ukPostCode = () => {
    let regexp =
      /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;

    if (regexp.test(this.state.postcode)) {
      this.props.setPostCode(this.state.postcode);
      this.setState({ postcodeYesNo: true });
    } else {
    }
  };

  submitPostcode = async () => {
    let result = await searchPostCode(this.state.postcode);
    this.capture(result);
  };

  capture = (result) => {
    this.setState({    
      towns: result.data.map((e) => e.posttown),
      outCode: result.data.map((e) => e.outcode), });
  };

  sendSearchTerm = (e) => {
    this.props.setPropertiesListArea(this.state.searchOutcode);
  };

  sendSearchTime = (time) => {
    this.props.setSearchTime(time);
  };

  searchTermInput = (e) => {
    this.setState({ autoCompleteListArea: e.target.value });
  };

  eType = () => {
    this.setState({ enquiryType: this.props.enquiryType });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.postcode !== this.state.postcode) {
      this.submitPostcode();
    }

    if (prevState.enquiryType !== this.props.enquiryType) {
      this.eType();
    }

    if (prevState.searchTerm.length !== this.state.searchTerm.length) {
        this.setState({visible:"show"});
    }
  };

  render() { console.log(this.state)
    return ( 
      <>
        <div className="search-box">
          <input
            className="inp-box rounded-pill background-magnifying-glass"
            type="text"
            value={this.state.searchTerm}
            onInput={this.postcodeInput.bind(this)}
            placeholder=" Location Search - Enter Postcode"
          />
          <div className="drop-down-box">
          <ul className="drop-down-box2">
            {this.state.searchTerm.length === 0 ? (
              <></>
            ) : this.state.visible === "hide" ? (
              <></>
            ) : (
              this.state.towns.map((item, index) => {
                return (
                  <li className="list-item"
                    onClick={() => {
                      this.setState(
                        {
                          searchOutcode: this.state.outCode[index],
                          visible: "hide",
                        },
                        () => {
                          this.eType();
                        }
                      );
                    }}
                    key={index}
                    value={item[index]}
                  >
                    {this.state.towns[index] + ", " + this.state.outCode[index]}
                  </li>
                );
              })
            )}
          </ul>
          </div>
        </div>

        {this.state.enquiryType === "" || this.state.searchOutcode === "" ? (
          <div className="submit-search">
          <Button className="main-btn"
        >
          Submit Search
        </Button>
        </div>
        ) : (
        <SubmitSearchButton 
            sendSearchTerm={this.sendSearchTerm()}
            searchOutcode={this.state.searchOutcode}
            currentTime={currentTime()} />
        )}
      </>
    );
  }
}

export default PostCodeConversion;
