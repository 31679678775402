import React, { Component } from 'react';
import {ReactComponent as SadFace} from '../icon/sad-svgrepo-com.svg' 


class NoResult extends Component {
    state = {  } 
    render() { 
        return (
        <div>
        <p>NO RESULTS FOUND</p>
        <SadFace/>
        </div>
        );
    }
}
 
export default NoResult;